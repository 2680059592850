<template>
  <div class="v-archive-fag">
    <TheHeading level="h1">Fag</TheHeading>

    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <div
      v-if="extraCards.length"
      class="v-archive-fag__cards v-archive-fag__cards--static"
    >
      <Card
        v-for="card in extraCards"
        :key="card.id"
        :path="`/fag/${courseSlug}/${chapterSlug}/${card.slug}`"
        :title="card.name"
        :button-text="$t('ARCHIVE_FAG_BTN_TEXT')"
        :disabled="!card.description"
        type="archive"
        class="v-archive-fag__card"
      />
    </div>
    <div v-if="cards.length" class="v-archive-fag__cards">
      <Card
        v-for="(card, i) in cards"
        :key="card.id"
        :path="`/fag/${courseSlug}/${chapterSlug}/${card.slug}`"
        :title="card.name"
        :pre-heading="`${$t('ARCHIVE_FAG_PRE_HEADING')} ${i + 1}`"
        :button-text="$t(card.description ? 'ARCHIVE_FAG_BTN_TEXT' : 'COMING')"
        type="archive"
        class="v-archive-fag__card"
        :disabled="!card.description"
      />
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed, onBeforeMount, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import Breadcrumbs from '@/components/Breadcrumbs'
import Card from '@/components/Card'
import TheHeading from '@/components/TheHeading'

export default {
  name: 'Home',

  components: {
    Breadcrumbs,
    Card,
    TheHeading,
  },

  setup() {
    const route = useRoute()
    const store = useStore()
    const { locale } = useI18n()
    const chapter = computed(() =>
      store.getters['content/getStructuresBySlugPath'](
        `fag/${courseSlug}/${chapterSlug}`,
      ),
    )
    const cards = computed(() =>
      store.getters['content/getStructuresChildrens'](
        chapter.value.id,
        'DIRECTORY',
      ).filter((ch) => /^(?!extra)/gi.test(ch.slug)),
    )
    const extraCards = computed(() =>
      store.getters['content/getStructuresChildrens'](
        chapter.value.id,
        'DIRECTORY',
      ).filter((ch) => /^extra/gi.test(ch.slug)),
    )
    const courseSlug = route.params.parent
    const chapterSlug = route.params.chapter
    const breadcrumbs = computed(() => {
      return [
        {
          name: 'Fag',
          to: '/fag',
        },
        {
          name: store.getters['content/getStructuresBySlugPath'](
            `fag/${courseSlug}`,
          ).name,
          to: `/fag#${courseSlug}`,
        },
        {
          name: store.getters['content/getStructuresBySlugPath'](
            `fag/${courseSlug}/${chapterSlug}`,
          ).name,
        },
      ]
    })

    const loadContent = async () => {
      await store.dispatch('content/setStructure', `fag/${courseSlug}`)
      await store.dispatch(
        'content/setStructure',
        `fag/${courseSlug}/${chapterSlug}`,
      )
      await store.dispatch('content/setStructuresChildren', {
        slugPath: `fag/${courseSlug}/${chapterSlug}`,
        query: { limit: 100, filter: `{"type": "DIRECTORY"}` },
      })
    }

    onBeforeMount(() => {
      loadContent()
    })

    watch(locale, () => {
      loadContent()
    })

    return {
      breadcrumbs,
      cards,
      courseSlug,
      chapterSlug,
      extraCards,
    }
  },
}
</script>

<style lang="scss">
.v-archive-fag__cards {
  overflow: auto;
  margin: 2rem -0.5rem;

  @include bp(small) {
    display: flex;
    flex-wrap: wrap;
  }

  &--static {
    margin-bottom: 0;
  }

  + div {
    margin-top: 0;
  }
}

.v-archive-fag__card {
  display: block;
  margin: 0.5rem;

  @include bp(small) {
    width: calc(50% - 1rem);
  }

  @include bp(medium) {
    width: calc(33% - 1rem);
  }

  @include bp(1320px) {
    width: calc(25% - 1rem);
  }

  .v-archive-fag__cards--static & {
    .c-card__text {
      padding: 3rem 1.25rem;
      text-align: center;
    }
  }
}
</style>
